import React from 'react'
import './DashboardMessagesSection.scss'

const DashboardMessagesSection = (props) => {

  return (
    <div className='dashboard-messages'>
      <div className='g-page-section'>
        <div className='g-section-header'>
          <div className='g-section-title'>Messages</div>
        </div>
      </div>
    </div>
  )
}  

export default DashboardMessagesSection