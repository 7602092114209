/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:fa0e0153-ffae-4807-ae48-5e229e0356e2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_2hoX5Hy1y",
    "aws_user_pools_web_client_id": "23mk3q07eqq7gcpevedp0p356g",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://jlm6lzpzgbh5xgdvgtpgdz2ppq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2oom5klzhrb4nd6zy2rb6qpvt4"
};

export default awsmobile;
